<template>
  <div class="flex justify-center items-center grow shrink basis-auto">
    <Empty v-if="!loading" :text="text" />
    <Spin v-else size="large"/>
  </div>
</template>
<script setup>
import { toRefs } from 'vue'
import Empty from '@/components/Loadings/Empty.vue'
import { Spin } from 'ant-design-vue';

const props = defineProps()
const {text, loading} = toRefs(props)

</script>
