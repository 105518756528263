<template>
  <div class="flex flex-col w-full">
    <Table
      v-if="data.length"
      :columns="columns"
      :data-source="data"
      :pagination="meta"
      @change="change"
    >
      <template #bodyCell="{ column, text, record }">
        <slot name="customFields" :column="column" :record="record" :text="text" />
      </template>
    </Table>
    <EmptyWithLoading
      v-else
      :loading="loading"
      text="No data"
    />
  </div>
</template>

<script>
import { Table } from 'ant-design-vue'
import EmptyWithLoading from '@/components/Loadings/EmptyWithLoading.vue'

export default {
  props: ['data', 'meta', 'columns', 'loading'],
  components: {
    Table,
    EmptyWithLoading
  },
  methods: {
    change(pagination) {
      this.$emit('getTableData', pagination)
    }
  }
}

</script>
